
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'UpcomingMatches',
    props: ['upcomingMatches'],
    methods: {
        getImgUrl(id: number): any {
            return require("@/assets/teamLogos/team_" + id + ".png");
        }
    },
})

