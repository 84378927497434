
import Header from '@/components/Header.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'App',
    components: {
        Header,
    },
});
