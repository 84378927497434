
import { defineComponent } from 'vue';
import store from '@/store';

export default defineComponent({
    name: 'LiveMatches',
    props: ["liveMatches"],
    methods: {
        getImgUrl(id: number): any {
            return require("@/assets/teamLogos/team_" + id + ".png");
        },
        matchDetail(id: number): void {
            store.commit("setMatchId", id);
            this.$router.push('/match-detail');
        }
    },
})
