import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeCarousel = _resolveComponent("HomeCarousel")!
  const _component_LiveMatches = _resolveComponent("LiveMatches")!
  const _component_UpcomingMatches = _resolveComponent("UpcomingMatches")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeCarousel, { lastWeekMatches: _ctx.lastWeekMatches }, null, 8, ["lastWeekMatches"]),
    _createVNode(_component_LiveMatches, { liveMatches: _ctx.liveMatches }, null, 8, ["liveMatches"]),
    _createVNode(_component_UpcomingMatches, { upcomingMatches: _ctx.upcomingMatches }, null, 8, ["upcomingMatches"])
  ]))
}